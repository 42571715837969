var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"d-flex justify-end mb-6",attrs:{"flat":"","tile":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"large":""},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSlashForward)+" ")])]},proxy:true}])})],1),_c('v-card',[_c('v-card-text',[_c('v-card-title',{staticClass:"text-no-wrap pt-1 ps-2"},[_vm._v(" PROVINSI")]),_c('v-btn',{staticClass:"me-3 mt-4",attrs:{"color":"accent"}},[_c('a',{attrs:{"href":"provinsis/create"}},[_c('v-icon',{attrs:{"color":"#ECEFF1"}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentSave)+" ")])],1)]),_c('v-text-field',{staticClass:"pt-5",attrs:{"label":"Search","dense":"","outlined":""},on:{"keyup":function($event){return _vm.doSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_vm._v("NO")]),_c('th',{staticClass:"text-center text-uppercase"},[_vm._v("NAMA PROVINSI")]),_c('th',{staticClass:"text-center text-uppercase"},[_vm._v("ACTION")])])]),_c('tbody',_vm._l((_vm.displayData()),function(data,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("capitalize")(data.nama_provinsi)))]),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"me-3 mt-4",attrs:{"to":{
                      name: 'provinsis-edit',
                      params: { id_provinsi: data.id_provinsi },
                    },"color":"warning"}},[_c('v-icon',{attrs:{"color":"#ECEFF1"}},[_vm._v(" "+_vm._s(_vm.icons.mdiGreasePencil)+" ")])],1),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"error","type":"button"},on:{"click":function($event){return _vm.deletePost(data.id_provinsi, index)}}},[_c('v-icon',{attrs:{"color":"#ECEFF1"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)],1)])}),0),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('center',[_c('div',{staticClass:"v-data-footer"},[_c('span',{staticClass:"v-data-footer__icons-before",attrs:{"bis_skin_checked":"1"}},[_c('button',{class:[
                            'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                            _vm.page == 1 ? 'v-btn--disabled' : '' ],attrs:{"type":"button","aria-label":"Previous page"},on:{"click":function($event){_vm.page--}}},[_c('span',{staticClass:"v-btn__content"},[_c('span',{staticClass:"v-icon notranslate theme--light",attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"v-icon__svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","role":"img","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"}})])])])])]),_vm._l((_vm.pages.slice(_vm.page - 1, _vm.page + 5)),function(pageNumber){return _c('span',{key:pageNumber,class:['v-data-footer', pageNumber == _vm.page ? 'active' : ''],on:{"click":function($event){_vm.page = pageNumber}}},[_c('button',{staticClass:"v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default",attrs:{"type":"button","aria-label":"Next page"}},[_vm._v(" "+_vm._s(pageNumber)+" ")])])}),_c('span',{staticClass:"v-data-footer__icons-after",attrs:{"bis_skin_checked":"1"}},[_c('button',{class:[
                            'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                            _vm.page < _vm.pages.length ? '' : 'v-btn--disabled' ],attrs:{"type":"button","aria-label":"Next page"},on:{"click":function($event){_vm.page++}}},[_c('span',{staticClass:"v-btn__content"},[_c('span',{staticClass:"v-icon notranslate theme--light",attrs:{"aria-hidden":"true"}},[_c('svg',{staticClass:"v-icon__svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","role":"img","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"}})])])])])])],2)])],1)])])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }